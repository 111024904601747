var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8b1b662b2b83ebd9cf33acf26934237b03f0ae3c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.1,
  integrations: [captureConsoleIntegration({ levels: ['error'] })],
  ignoreErrors: [
    'hydrating',
    'Hydration',
    'server-rendered HTML',
    'Failed to fetch',
    'Cancel rendering route',
    'Non-Error exception captured', // NOTE: https://github.com/getsentry/sentry-javascript/issues/2292
  ],
});
